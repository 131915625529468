import {extendTheme} from '@chakra-ui/react';

const makeTheme = (globalStyles: Record<string, unknown> = {}) =>
	extendTheme({
		fonts: {
			heading: 'Barlow',
			body: 'Inter',
		},
		components: {
			Code: {
				baseStyle: {
					px: 2,
					py: 1,
					borderRadius: 'lg',
				},
			},
			Button: {
				variants: {
					navigation: {
						w: {base: 'full', md: 'auto'},
						p: 0,
						fontWeight: '600',
						color: 'gray.700',
						fontFamily: 'Barlow',
						transition: 'all 0.1s',
						borderRadius: '0',
						_hover: {
							borderBottom: '2px solid',
							borderColor: 'blue.500',
						},
					},
				},
			},
		},
		colors: {
			black: '#0e1012',
			gray: {
				'50': '#F7F7F9',
				'100': '#EEEEF2',
				'200': '#DDDDE5',
				'300': '#CACAD5',
				'400': '#A7A5B8',
				'500': '#86839F',
				'600': '#6B6883',
				'700': '#524F66',
				'800': '#3A384A',
				'900': '#23222D',
			},
			teal: {
				'50': '#f3fbfb',
				'100': '#cdefee',
				'200': '#a0e0de',
				'300': '#67ceca',
				'400': '#15b4ae',
				'500': '#019994',
				'600': '#017c78',
				'700': '#01615d',
				'800': '#00514e',
				'900': '#004340',
			},
			cyan: {
				'50': '#E6FCFF',
				'100': '#CCF8FC',
				'200': '#99F1FA',
				'300': '#66E9F7',
				'400': '#33E2F5',
				'500': '#0FD2E9',
				'600': '#0CABBC',
				'700': '#09848F',
				'800': '#065D62',
				'900': '#033635',
			},
			blue: {
				'50': '#f2f7fb',
				'100': '#cee0ef',
				'200': '#abc9e4',
				'300': '#84b0d7',
				'400': '#5e99cb',
				'500': '#3d83c0',
				'600': '#196db5',
				'700': '#015197',
				'800': '#01437c',
				'900': '#013766',
			},
			purple: {
				'50': '#F7F2FE',
				'100': '#EBE0FD',
				'200': '#D7C1FB',
				'300': '#BA8FF8',
				'400': '#8B47F2',
				'500': '#5d18cf',
				'600': '#4B13A8',
				'700': '#3A0E82',
				'800': '#28095B',
				'900': '#170534',
			},
			pink: {
				'50': '#FEF2F6',
				'100': '#FDE0E9',
				'200': '#FCC3D7',
				'300': '#FB9CBC',
				'400': '#FA68A0',
				'500': '#F8346F',
				'600': '#E81555',
				'700': '#C20D43',
				'800': '#9C0834',
				'900': '#750628',
			},
			orange: {
				'50': '#fdfaf8',
				'100': '#f6ebe2',
				'200': '#ebd5c1',
				'300': '#dcb592',
				'400': '#ce9767',
				'500': '#c17c3f',
				'600': '#b45f16',
				'700': '#974701',
				'800': '#773801',
				'900': '#622e01',
			},
			yellow: {
				'50': '#FFFBF0',
				'100': '#FFF3D6',
				'200': '#FFE7AD',
				'300': '#FFDB85',
				'400': '#FFD15D',
				'500': '#FFC635',
				'600': '#FFB802',
				'700': '#CC9200',
				'800': '#996E00',
				'900': '#664900',
			},
			green: {
				'50': '#f7fcfa',
				'100': '#d5f1e2',
				'200': '#a7e2c3',
				'300': '#71d19e',
				'400': '#32bd73',
				'500': '#01a54d',
				'600': '#018840',
				'700': '#016a32',
				'800': '#015729',
				'900': '#004822',
			},
			primary: {
				'50': '#F4F4F7',
				'100': '#E6E5EC',
				'200': '#CECCD9',
				'300': '#B5B2C7',
				'400': '#9C98B4',
				'500': '#2B284D',
				'600': '#232041',
				'700': '#1C1935',
				'800': '#141229',
				'900': '#0D0B1D',
			},
		},
		styles: {
			global: globalStyles,
		},
	});

export default makeTheme;
